<template>
<div class="wrapper_dialog BAB_wra">
  <div class="relative">
    <transition name="fade" mode="out-in" appear>
    <div class="container">
      <div class="top">
        <span>{{ $t("menu.connectWallet") }}</span>
        <a href="javascript:;" @click="closeDialog"><img src="../../../../../public/images/icon_close.png" alt=""></a>
      </div>
      <div class="wallet_items">
        <a href="javascript:;" @click="chooseWallet" data-type="MetaMask">
          <span>MetaMask</span>
          <img src="../../../../../public/images/img-MetaMask.png" alt="">
        </a>
        <a href="javascript:;" @click="chooseWallet" data-type="chooseWallet">
          <span>TrustWallet</span>
          <img src="../../../../../public/images/img-TrustWallet.png" alt="">
        </a>
        <a href="javascript:;" @click="chooseWallet" data-type="chooseWallet">
          <span>MathWallet</span>
          <img src="../../../../../public/images/img-MathWallet.png" alt="">
        </a>
        <a href="javascript:;" @click="chooseWallet" data-type="chooseWallet">
          <span>TokenPocket</span>
          <img src="../../../../../public/images/img-TokenPocket.png" alt="">
        </a>
      </div>
      <div class="check_btn">{{ $t("menu.viewWalletTutorial") }}</div>
    </div>
    </transition>
  </div>
</div>
</template>
<script>
import '../../../../components/dialog/style/WalletDialog.css';
// const starPool = require('../../utils/starPool')
export default {
  name: 'PurchasingWallet',
  props:['id'],
  data() {
    return {
      status:0,//是否连接成功 0是未成功  1是成功
      refEventModels:[]
    }
  },
  methods: {
    closeDialog:function(){
      if(this.status==0){
        this.$emit('transfer',0,2)//向父组件RightNav.vue传递的数据connectStatus=0,type=2
      }
      if(this.status==1){
        this.$emit('transfer',1,2)//向父组件RightNav.vue传递的数据connectStatus=1,type=2
      }
    },
    chooseWallet(e){

      let {type} = e.target.dataset;
      if (typeof window.ethereum == 'undefined') {
        this.$toast(this.$t('menu.pleaseInstall')+type+this.$t('menu.wallet'))
        return false
      }
      const provider = window.ethereum;
      provider.request({ method: 'eth_requestAccounts' }).then((res)=>{
        var address = res[0]
        console.log("当前钱包地址："+address)
        localStorage.setItem('address',address)

        this.status=1;
        this.$toast(this.$t('menu.connectionSuc'));
        this.closeDialog();
      })


      //调试跳转看效果
      // this.status=1;
      // this.$toast("连接成功");
      // this.closeDialog();
    }
  },
}
</script>
<style scoped>
.wrapper_dialog .container{
  background-color: #323232;
  height: 38rem;
  margin-top: -19rem;
}
.wallet_items a, .wallet_items button {
  background: url(../../../../../public/images/Purchasing/box3.png) no-repeat;
  background-size: 100% 100%;
}
</style>